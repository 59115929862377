import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

// ----------------------------------------------------------------------

const AppChatPage = lazy(() => import('src/pages/embeds/app-chat'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const embedRoutes = [
  {
    path: 'embeds',
    element: (
      <Suspense fallback={<LinearProgress />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      { element: <BlankPage />, index: true },
      { path: 'chat', element: <AppChatPage /> },
    ],
  },
];
