import { createContext, useContext } from 'react';

// ----------------------------------------------------------------------

export const BookingContext = createContext({});

export const useBookingContext = () => {
  const context = useContext(BookingContext);

  if (!context) throw new Error('useBookingContext must be use inside BookingProvider');

  return context;
};
