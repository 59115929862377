import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import { useEffect, useMemo, useCallback } from 'react';
// hooks
import { useLocalStorage, getStorage } from 'src/hooks/use-local-storage';
import { useRouter } from 'src/routes/hooks';
// api
// import { useGetCompanies } from 'src/api/company';
//
import { BookingContext } from './booking-context';

// ----------------------------------------------------------------------

const STORAGE_KEY = 'booking';

const initialState = {
  doctors: [],
  dates: [new Date(), new Date()],
  company: null,
  department: null,
};

export function BookingProvider({ children }) {
  const router = useRouter();

  // const { companies } = useGetCompanies();

  const { state, update, reset } = useLocalStorage(STORAGE_KEY, initialState);

  // useEffect(() => {
  //   if (!state.company && companies?.[0]) {
  //     update('company', companies[0]);
  //   }
  // }, [companies, state, update]);

  const onSelectDoctors = useCallback(
    (doctors) => {
      update('doctors', doctors);
    },
    [update]
  );

  const onSelectCompany = useCallback(
    (company) => {
      update('company', company);
    },
    [update]
  );

  const onSelectDepartment = useCallback(
    (department) => {
      update('department', department);
    },
    [update]
  );

  const onSelectDates = useCallback(
    (dates) => {
      update('dates', dates);
    },
    [update]
  );

  const memoizedValue = useMemo(
    () => ({
      ...state,
      //
      onSelectDoctors,
      onSelectDates,
      onSelectCompany,
      onSelectDepartment,
    }),
    [
      onSelectDoctors,
      onSelectDates,
      onSelectCompany,
      onSelectDepartment,
      state,
    ]
  );

  return <BookingContext.Provider value={memoizedValue}>{children}</BookingContext.Provider>;
}

BookingProvider.propTypes = {
  children: PropTypes.node,
};
