import axios from 'axios';
// config
import { PP_SWITCH_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: PP_SWITCH_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    login: '/public/dashboard/login',
    me: '/dashboard/user/profile',
  },
  user: {
    doctor: '/dashboard/user/consultant',
  },
  entity: {
    department: '/dashboard/entity/department',
    company: '/dashboard/entity/company',
  },
  appointment: {
    root: '/dashboard/appointment',
    uhid: '/dashboard/appointment/uhid',
  },
  booking: {
    root: '/admin/bookings',
    update: '/admin/update/booking',
    vendorInvite: (bookingId, vendorId) => `/admin/assignment/request/${vendorId}/${bookingId}`
  },
  price: {
    root: '/admin/prices',
    update: '/admin/update/price'
  },
  vendor: {
    all: '/admin/vendor/getAll',
    update: (status, id) => `/admin/vendor/${status}/${id}`,
  },
  post: {
    root: '/admin/post',
    list: '/admin/post/list',
    details: '/admin/post/details',
    latest: '/admin/post/latest',
    search: '/admin/post/search',
  },
  cms: {
    uploads: '/cms/uploads',
    pageJson: '/cms/page/json',
    deploy: '/cms/deploy',
    status: '/cms/status',
  }
};
